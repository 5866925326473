import React from 'react';

    import {
    Form, Input, Button, Spin
} from 'antd';
import NavLink from 'react-router-dom/NavLink';

import {connect } from 'react-redux'
import connectAdvanced from 'react-redux/lib/components/connectAdvanced';

import * as actions from '../store/actions/auth';
import {UserOutlined, MailOutlined, LockOutlined,LoadingOutlined} from '@ant-design/icons'

const FormItem = Form.Item; 
const antIcon = <LoadingOutlined />;



class NormalLoginForm extends React.Component {
    handleSubmit = values => {

        this.props.onAuth(values.username, values.password);
        this.props.history.push('/');
//         this.forceReload();
//         window.location.reload();
    }

    render() {


        let errorMessage = null;
        if (this.props.error) {
            errorMessage = (<p>{this.props.error.message} </p>);
        }
        return (
            <div >
                {errorMessage}
                {
                    this.props.loading ? 
                        <Spin indicator={antIcon} />
                        :
                    <Form onFinish={this.handleSubmit} className="login-form"
                         name="basic"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 24 }}
                          initialValues={{ remember: true }}
                          autoComplete="off"
                          style={{width:'100',padding: '24px 24px 24px 24px',height: '100',backgroundColor:'#041527' }}
                    >
                        <Form.Item name="username" rules= {[{ required: true, message: 'Please input your username!' }]}>
                                <Input prefix={<UserOutlined/>} placeholder="Username" />
                        </Form.Item>
                        <Form.Item name = "password" rules= {[{ required: true, message: 'Please input your Password!' }]}>
                                <Input prefix={<LockOutlined/>} type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' style={{ marginRight: '10px' }}>
                                Login
                            </Button>
                            Or
                            <NavLink style={{ marginRight: '10px' }}
                                    to='/signup/'> signup
                            </NavLink>
                        </Form.Item>

                            
                    </Form>
                    
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispath => {
    return {
        onAuth: (username, password) => dispath(actions.authLogin(username,password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);