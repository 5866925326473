import React from 'react';

import { Layout, Menu, Breadcrumb, Space, Select,Button, message } from 'antd';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import {UserOutlined, MailOutlined, LockOutlined,LoadingOutlined,HomeOutlined,StockOutlined} from '@ant-design/icons'
import axios from '../store/axioss';
import DropDownEditor from '../components/DropDownEditor'
import FixedReactDataGrid from '../components/DataGridForm';

import { Row, Col } from 'antd';
import {Area} from '@ant-design/charts'


const { Header, Content, Footer, Sider } = Layout;
const {SubMenu, MenuItemGroup} = Menu

const { Option } = Select;

const quoteColumns = [
  { key: "SHORTNAME", name: "Short Name", editable: false, on:"ISIN",from: "SHORTNAME",width:150},
  { key: "ISIN", name: "ISIN", editable: true ,width:150},
  { key: "bid_size", name: "Bid Size", editable: true },
  { key: "bid", name: "Bid", editable: true },
  { key: "ask", name: "Ask", editable: true },
  { key: "ask_size", name: "Ask Size", editable: true },
  { key: "security", name: "Security", editable: false,hidden:true, on:"ISIN",from:"id" },
  { key: "ord", name: "ord", editable: false,hidden:true },

];

class Quote extends React.Component {

    state = {
        securities:[],
        quotes:[],
        selectedSec:undefined
    }
    load_data = () => {
        if(this.props.token){
            axios.defaults.headers.common = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
            }

            this.get_securities()
            .then(res => {
                axios.get('/api/quote/')
                .then(
                    res => {
                        this.setState({
                            quotes: res.data.map(t1 => ({...t1,...this.state.securities.find(t2 => t2.id === t1.security).seccode})),
                        })
                    }
                )
                .catch( error => {console.log(error)})
            })
        }
    }

    get_securities = async () => {
        console.log('securities storage')
        const sec = JSON.parse(localStorage.getItem('securities'))
        console.log(sec)
        if(sec){
            this.setState({securities:sec})
        } else {
            axios.defaults.headers.common = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
            }
            const res = await axios.get('/api/sec/')
            console.log(res)
            localStorage.setItem('securities',JSON.stringify(res.data))
            this.setState({securities:res.data})

        }
    }
    componentDidMount() {
        this.load_data()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.securities === null) {
          this.load_data();
        }
    }

    fieldValueChanged = (f,v) => {
        console.log('quotes field changed')
        console.log(f)
        console.log(v)
//         let quotes = this.state.quotes
//         quotes = v
        this.state.quotes = v
//         if(v.filter(v => v.security).length !== v.length){
//             console.log('empty sec found')
//             let newQuotes = v.map(v => (v.security || this.state.securities.filter(s => s.SECID==='RU000A0JXB41').length===0)?
//                                 ({...v})
//                                 :
//                                 ({...v,'security':this.state.securities.find(s => s.SECID==='RU000A0JXB41').id}))
//             console.log(newQuotes)
//             this.setState({quotes:newQuotes})
//         }

    }

    handleChange= value => {
        console.log('setting secid')
        console.log(value)
        this.setState({ selectedSec:parseInt(value) });
    };

    addQuote = () =>{
//         console.log(this.state.selectedSec)
        console.log(this.state.securities.find(s => s.id === 560))

        if(this.state.quotes.filter(q => q.security ===this.state.selectedSec).length===0){
            var newQuotes = this.state.quotes
            console.log(this.state.selectedSec)
            newQuotes.push({'security':this.state.selectedSec,'ISIN':this.state.securities.find(s => s.id === this.state.selectedSec).ISIN})
            this.setState({quotes:newQuotes})
        }
        else
            message.error('Quote exists')
    }
    updateQuotes = () => {
        if(this.props.token !== null)
        {
            axios.defaults.headers = {
                    "Content-Type": "application/json",
                    Authorization: 'Token '+this.props.token
            }
            console.log(this.state.quotes);
            let quotes = this.state.quotes;
            //curve.upd_time = (new Date()).toJSON();
            console.log('new quotes')
            console.log(quotes)

                return axios.post('/api/quote/', quotes.filter(q => q.security !== ""))
                  .then(res => {
                      console.log("Post result");
                        console.log(res);
                        this.setState({quotes:res.data});
                        message.success('Quotes updated');
                  })
                  .catch(error => {
//                     console.log(error.response);
                    for(const [i,d] of error.response.data.entries())
                        for(const f in d){
                            message.error( this.state.quotes[i].SHORTNAME+', ' + quoteColumns.find(c => c.key === f).name + ' : ' + d[f][0])
                        }
                    })
        }

  }

    render(){
        return (
            <Layout theme="dark" style={{lineHeight:'20px',marginBottom: 0,marginTop: 0, height:'90vh', background: '#041527'}}>

                <Row>
                <Col span={15}>
                    <div style={{padding:6,width:700}}>
                    <Select showSearch
                        style={{ width: 200 }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange = {this.handleChange}
                      >
                    {this.state.securities.map(s => <Option key={s.id}>{s.SHORTNAME}</Option>)}
                    </Select>
                    <Button onClick = {this.addQuote}>Add Quote</Button>
                    <FixedReactDataGrid
                        rows={this.state.quotes.sort((a,b)=>(a.ord > b.ord)?1:-1).map(t1 => t1.security?({...t1,
//                                                                     SHORTNAME: this.state.securities.find(t2 => t2.id === t1.security).SHORTNAME,
                                                                    ISIN: this.state.securities.find(t2 => t2.id === t1.security)?.ISIN
                                                                    })
                                                                    :
                                                                    ({...t1,
//                                                                     SHORTNAME:this.state.securities.find(t2 => t2.SECID === t1.SECID).SHORTNAME,
                                                                    security:this.state.securities.find(t2 => t2.SECID === t1.SECID)?.id

                                                                }))}
                        columns={quoteColumns}
                        editable={true}
                        canAddRows={true}
                        changedID={true}
                        onChange={this.fieldValueChanged}
                        extraData = {this.state.securities}
                        />
                        <Button onClick={this.updateQuotes} htmlType='submit'>Update Quotes</Button>
                    </div>
                </Col>
                </Row>
            </Layout>
        )
    }
}
const mapStateToProps = state => {
  return {
    token: state.token,
    securities: state.securities
  }
}


export default connect(mapStateToProps)(Quote);