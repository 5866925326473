import React from 'react';

import { Route } from 'react-router-dom';

import CurveList from './containers/CurveListView';
import CurveDetail from './containers/CurveDetailView';
import CurveListDetail from './containers/CurveListDetailView';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Home from './containers/Home';
import Quote from './containers/Quotes';


const BaseRouter = () => (
    <div>
        <Route exact path='/' component={Home} />
        <Route exact path='/mycurves/' component={CurveListDetail} />
        <Route exact path='/myquotes/' component={Quote} />
        <Route exact path='/login/' component={Login} />
        <Route exact path='/signup/' component={Signup} />

    </div>
);

export default BaseRouter;
