import React from 'react';

import Curves from '../components/CurveList'
import CurveForm from '../components/CurveForm';
import axios from 'axios';



class CurveList extends React.Component {

    state = {
        curves: []
    }

    componentDidMount() {
        axios.get('http://127.0.0.1:8000/api/curve/')
            .then(
                res => {
                    this.setState({
                        curves: res.data
                    });
                }
            )
        axios.get('http://127.0.0.1:8000/api/instr/')
            .then(
                res => {
                    this.setState({
                        instruments: res.data
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Curves data={this.state.curves} />
                <br />
                <h2>Add Curve</h2>
                <CurveForm data={this.state.curves}
                    requestType='post'
                    btnText='Create'
                />

            </div>
        )
    }
}

export default CurveList;