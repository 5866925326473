import React from "react";
import ReactDataGrid from "fixed-react-data-grid";
/*
Comment above ReactDataGrid import and uncomment below import to see that the issue is fixed
*/
// import ReactDataGrid from "fixed-react-data-grid";
// import ReactDataGrid from "fixed-react-data-grid";
import { range } from "lodash";
import { Button, Row, Col, message } from 'antd';
import './DataGridForm.css'

const defaultParsePaste = str => {
  console.log(str);
  return str.split(/\r\n|\n|\r/).map(row => row.split("\t"));
};



const changedValues = {};
class FixedReactDataGrid extends React.Component {
  constructor(props) {
    super(props);
    const defaultColumnProperties = {
      resizable: true,
      // filterable: true,
      // filterRenderer: AutoCompleteFilter,
      editable: true,
      canAddRows: true
      // sortable: true
    };
    try {
            this.state={
              topLeft: {},
              botmRight: {},
              filters: "",
              rows:[this.new_row()],
              selectedRow: 0
            };
            if(props.rows && props.rows.length>0){
                console.log('Data grid new rows');
                console.log(this.props.rows);
                const rows= props.rows
//                 if(this.props.extraData){
//                     for (let i = 0; i < rows.length; i++) {
//                         rows[i] = {...rows[i],...this.fetch_columns2(rows[i])}
//                     }
//                 }
                this.state.rows=rows
                if(rows?.length >0)
                    this.updateRows(0,rows)
            }
        }
    catch (e) {console.log(e)}


    // Copy paste event handler
    if(this.props.editable){
        document.addEventListener("copy", this.handleCopy);
        document.addEventListener("paste", this.handlePaste);
    }
    // document.addEventListener('keydown', this.handleEnter);
  }

  componentWillReceiveProps = (props) => {
        console.log('DGF props');
        console.log(props)
        if(props.changedID){
            if(props.rows.length>0){

//                     const ed = this.getExtra(rows)
//                     for (let i = 0; i < rows.length; i++) {
//                         rows[i] = {...rows[i],...this.fetch_columns2(rows[i],ed)}
//                     }
                    this.state.rows = props.rows
                    this.updateRows(0,props.rows)
            }
            else this.setState({ rows: [this.new_row()] })
        }

    }

  componentWillUnmount() {
    this.removeAllListeners();
  }

  removeAllListeners = () => {
    document.removeEventListener("copy", this.handleCopy);
    document.removeEventListener("paste", this.handlePaste);
    // document.removeEventListener('keydown', this.handleEnter);
  };

  getExtra = async (rows) => {
    console.log('getting extra data')
    console.log(rows)
    if(this.props.getExtraData){
        return this.props.getExtraData(rows)
    } else {
        return this.props.extraData
    }

  }

  fetch_columns2(row,extraData) {
    const ed = extraData? extraData : this.props.extraData;
    if(row && ed){
        let oldRow = {...row}
        const map_columns = this.props.columns.filter(c => c.on)
        oldRow= {...oldRow,...map_columns.reduce((acc,c) => {
                            acc[c.key]=oldRow[c.on] && ed.filter(d => d[c.on]===oldRow[c.on]).length>0?
                                ed.find(d => d[c.on]===oldRow[c.on])[c.from]
                                :
                                "";
                            return acc},{}) }
        return oldRow;
        }
  }
//   fetch_columns() {
//     if(this.state.rows){
//         const oldRows = this.state.rows.slice()
//         const map_columns = this.props.columns.filter(c => c.on)
//         console.log(map_columns)
//         console.log(oldRows)
//         console.log(this.props.extraData)
//         console.log(oldRows.length)
//         for (let i = 0; i < oldRows.length; i++) {
//             oldRows[i]= {...oldRows[i],...map_columns.reduce((acc,c) => {
//                                 acc[c.key]=oldRows[i][c.on] && this.props.extraData.filter(d => d[c.on]===oldRows[i][c.on]).length>0?
//                                     this.props.extraData.find(d => d[c.on]===oldRows[i][c.on])[c.key]
//                                     :
//                                     "";
//                                 return acc},{}) }
//         }
//         this.setState({rows:oldRows})
//     }
//   }

  new_row() {
    return this.props.columns.reduce((acc,x) => {acc[x.key]=undefined;return acc},{})
  }

  rowGetter = i => {
    const { rows } = this.state;
        return rows[i];
  };

  updateRows = (startIdx, newRows) => {
      const rows = this.state.rows.slice();
      const length = this.props.canAddRows?newRows.length:Math.min(this.state.rows.length-startIdx, newRows.length)
      for (let i = 0; i < length; i++) {
        if (startIdx + i < rows.length) {
          changedValues[startIdx + i] = {
            ...changedValues[startIdx + i],
            ...newRows[i]
          };
        }
        rows[startIdx + i] = { ...rows[startIdx + i], ...newRows[i] };
      }

      const ed = this.getExtra(rows)
      ed.then( res => {
          console.log('received extra data');
          console.log(res)
          for (let i = 0; i < length; i++) {
            rows[startIdx + i] = { ...rows[startIdx + i],...this.fetch_columns2(rows[startIdx + i],res)};
          }
          this.setState({rows:rows})
          console.log("points updated 1")
            console.log(this.state.rows)
            if(this.props.editable){
                this.props.onChange("points", this.state.rows);
            }
      })
      .catch(error => {message.error(error.response.data)})


//     this.fetch_columns()


  };

  handleCopy = e => {
    console.log(e);
    e.preventDefault();
    const { topLeft, botmRight } = this.state;
    // Loop through each row
    const text = range(topLeft.rowIdx, botmRight.rowIdx + 1)
      .map(
        // Loop through each column
        rowIdx =>
          this.props.columns
            .slice(topLeft.colIdx, botmRight.colIdx + 1)
            .map(
              // Grab the row values and make a text string
              col => this.rowGetter(rowIdx)[col.key]
            )
            .join("\t")
      )
      .join("\n");
    // console.log(text)
    e.clipboardData.setData("text/plain", text);
  };

  handlePaste = e => {
    e.preventDefault();
    const { topLeft } = this.state;

    const newRows = [];
    const pasteData = defaultParsePaste(e.clipboardData.getData("text/plain"));

    pasteData.forEach(row => {
      const rowData = {};
      // Merge the values from pasting and the keys from the columns
      this.props.columns
        .slice(topLeft.colIdx, topLeft.colIdx + row.length)
        .forEach((col, j) => {
          // Create the key-value pair for the row
          rowData[col.key] = row[j];
        });
      // Push the new row to the changes
      newRows.push(rowData);
    });

    this.updateRows(topLeft.rowIdx, newRows);
  };
  setSelection = args => {
    // console.log(args)
    this.setState({
      topLeft: {
        rowIdx: args.topLeft.rowIdx,
        colIdx: args.topLeft.idx
      },
      botmRight: {
        rowIdx: args.bottomRight.rowIdx,
        colIdx: args.bottomRight.idx
      }
    });
    //this.updateRows(0,this.state.rows)
  };
  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
      console.log('onGridRowsUpdated')
      const rows = this.state.rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
    console.log(rows)
    this.updateRows(0,rows);


//     this.fetch_columns()
    console.log("points updated 2")
    console.log(this.state.rows)
    if(this.props.editable){
        this.props.onChange("points", this.state.rows);
    }
  }

  onCellSelected = (event) => {
    this.setState({selectedRow:event.rowIdx});
  }
  addRow = e => {
    var newRows = this.state.rows;
    newRows.splice(this.state.selectedRow+1,0,this.new_row())
    this.updateRows(0,newRows)
  }
  deleteRow = e => {
    var newRows = this.state.rows;
    if(newRows.length>0)
        newRows.splice(this.state.selectedRow,1)
    else
        newRows = [this.new_row()]
    this.updateRows(0,newRows)
  }

  handleKeyDown = (event) => {
   //e.preventDefault();
   const { topLeft } = this.state;
   if (event.which === 13 && this.props.editable) { // Enter key pressed
      let currentRowIndex = this.state.selectedRow
      let lastRowIndex = this.state.rows.length - 1
      console.log('currentrow');
      console.log(this.props);
      console.log(lastRowIndex);
      if (currentRowIndex === lastRowIndex) { // We are at the last row
         // Call your method that adds a new row here.
         // Mine has lots of controls and uses redux, so I'm not pasting it.

         this.updateRows(currentRowIndex+1,[this.new_row()])
         //this.refs.grid.openCellEditor(currentRowIndex + 1, this.refs.grid.state.selected.idx)
      }
   }
}

  render() {
    return (
      <Row>
          <Col span='23'>
          <ReactDataGrid
            ref="grid"
            columns={this.props.columns.filter(c => !c.hidden)}
            rowGetter={i => this.state.rows[i]}
            rowsCount={Math.max(1,this.state.rows.length)}
            onGridRowsUpdated={this.onGridRowsUpdated}
            enableCellSelect={true}
            cellRangeSelection={{
              onComplete: this.setSelection
            }}
            onGridKeyDown={this.handleKeyDown.bind(this)}
            onCellSelected = {this.onCellSelected}
            editable = {this.props.editable}
            style={{backgroundColor:'#000000'}}
            minHeight={Math.min(25,Math.max(10,this.state.rows.length+1))*35}
          />
          </Col>
          <Col span='1'>
            {this.props.editable && this.props.canAddRows?
              <div>

                  <Button onClick = {this.addRow}  htmlType="submit" style={{ width: '12px' }}>+</Button>
                  <Button onClick = {this.deleteRow} style={{ width: '12px' }}>-</Button>
              </div>
              :
              <p/>
          }
          </Col>
      </Row>
    )
  }
}

export default FixedReactDataGrid;
