import React from 'react';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const HrefFormatter = ({ row }) => (
    <a href={`/${row.id}`}>{row.id}</a>
);

const HrefTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={HrefFormatter}
        {...props}
    />
);

const GridColumns =
    [
        { name: 'id', title: 'Name' }
    ]

const Curves = (props) => {

    return (
        <Grid
            rows={props.data}
            columns={GridColumns}>
            <HrefTypeProvider
                for='id'
            />
            <Table />
            <TableHeaderRow />
        </Grid>
    )
}


export default Curves;