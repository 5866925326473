import React from 'react';

import axios from '../store/axioss';

import FixedReactDataGrid from './DataGridForm';
//import { Form, Input, Button, Select } from 'antd';


import Form, { Item, GroupItem, Label, ButtonItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';

import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';



import { range } from "lodash";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';

import DataGrid, {
    Column,
    Editing,
    Paging,
    Lookup
} from 'devextreme-react/data-grid';


const defaultParsePaste = str => {
  console.log(str);
  return str.split(/\r\n|\n|\r/).map(row => row.split("\t"));
};
const changedValues = {};

class CurveForm extends React.Component {
    state = { curve: {}};
    constructor(props) {
        super(props);
        try {
            let curve = props.curve
            this.setState({
                curve:curve
            });

        }
        catch { }

        this.pointopt = [{ id: 1, name: '1D' },
        { id: 2, name: '1W' },
        { id: 3, name: '2W' },
        { id: 4, name: '1M' },
        { id: 5, name: '3M' },
        { id: 6, name: '6M' },
        { id: 7, name: '1Y' },
        { id: 8, name: '2Y' },
        { id: 9, name: '3Y' },
        { id: 10, name: '4Y' },
        { id: 11, name: '5Y' },
        { id: 12, name: '6Y' },
        { id: 13, name: '7Y' },
        { id: 14, name: '10Y' },
        { id: 15, name: '15Y' },
        { id: 16, name: '20Y' },
        { id: 17, name: '25Y' },
        { id: 18, name: '30Y' }
        ]
        this.columnDefs = [
           { field: "name" },
           { field: "value" }
       ]

    }
    fieldValueChanged = (e) => {
        console.log(e);
        if (e.target)
            this.props.onChange(e.target.name, e.value);
    }

    componentWillReceiveProps = (props) => {
        console.log('received Props Curve Form');
        console.log(props);
        this.setState({ curve: props.curve });
    }


  handleFormSubmit = (event, requestType, curveID) => {
    console.log(this.state.curve);
    let curve = this.state.curve;
    curve.upd_time = (new Date()).toJSON();


    switch (requestType) {
      case 'post':

        return axios.post('http://127.0.0.1:8000/api/', curve)
          .then(res => console.log(res))
          .catch(error => console.error(error))



      case 'put':
        console.log(curve);
        return axios.put('http://127.0.0.1:8000/api/curve/${curveID}/',
          curve
        )
          .then(res => {
            console.log("Update result");
            console.log(res);
            this.setState({curve:res.data});
            this.forceUpdate();
          })
          .catch(error => console.error(error))
    }

  }

  handleDelete = (event) => {
    const curveID = this.props.match.params.curveID;
    axios.delete('http://127.0.0.1:8000/api/curve/${curveID}/');
    this.props.history.push('/');
    this.forceUpdate();
  }
  fieldValueChanged = (e) => {
    console.log(e);
    if (e.target)
      this.props.onChange(e.target.name, e.value);
  }


    renderPoints = (props) => {

        if (this.state.curve && this.state.curve.points){
            console.log('rendering points')
            console.log(this.props);
            console.log(this.state.curve.points)
            return (
                <div>
                    <FixedReactDataGrid rows={this.state.curve.points} onChange={this.props.onChange} changedID={this.props.changedID} />
                </div>
            )
        }
        else return (<a />)

    }

    render() {
        console.log('Render Curve Form')
        console.log(this.state.curve);

        const isUpdate = this.props.requestType === 'put';
        return (
            <div>

                <Form formData={this.state.curve} width={500}>
                    <Item itemType='empty' />
                    <Item label="ID" dataField='id' />
                    <Item label="Curve Name" dataField='name' />
                    <Item label="Updated" dataField='upd_time' disabled={true} />
                    <Item label="Points" dataField='points' render={this.renderPoints} />
                </Form >
                 <Button text="Submit" type='default' onClick={(event) => this.handleFormSubmit(
                  event,
                  this.props.requestType,
                  this.props.curveID
                )} />
                <Button text="Delete" type='danger' onClick={(event) => this.handleDelete(
                  event)} />
            </div>
        );
    }
}

export default CurveForm;