import React from 'react'


import {
    Form, Input, Button
} from 'antd';

import {UserOutlined, MailOutlined, LockOutlined} from '@ant-design/icons'

import { connect } from 'react-redux';
 
import * as actions from '../store/actions/auth';

import NavLink from 'react-router-dom/NavLink';


class RegistrationForm extends React.Component {
    state = {
        confirmDirty: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onAuth(
                    values.username,
                    values.email,
                    values.password,
                    values.confirm);
            }
        });
        this.props.history.push('/');

    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }



    render() {
        return (
            <Form onFinish={this.handleSubmit}>
                <Form.Item name="username" rules= {[{ required: true, message: 'Please input your username!' }]}>
                                <Input prefix={<UserOutlined/>} placeholder="Username" />
                        </Form.Item>
                <Form.Item name="email" rules= {[{
                            type: 'email', message: 'The input is not valid E-mail!',
                        }, {
                            required: true, message: 'Please input your E-mail!',
                        }]}>

                        <Input prefix={<MailOutlined />} placeholder="Email"/>

                </Form.Item>
                <Form.Item name = "password" rules= {[{ required: true, message: 'Please input your Password!' }]}>
                                <Input prefix={<LockOutlined/>} type="password" placeholder="Password" />
                        </Form.Item>
                <Form.Item name = "confirm" rules= {[{
                            required: true, message: 'Please confirm your password!',
                        }, {
                            validator: this.compareToFirstPassword,
                        }]}>

                        <Input type="password" onBlur={this.handleConfirmBlur} prefix={<LockOutlined />} type="password" placeholder="Password"/>

                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' style={{ marginRight: '10px' }}>
                        Signup
                            </Button>
                    Or
                            <NavLink style={{ marginRight: '10px' }}
                        to='/login/'> login
                            </NavLink>
                </Form.Item>
                
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispath => {
    return {
        onAuth: (username, email, password1, password2) => dispath(actions.authSignup(username, email, password1, password2))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm)
