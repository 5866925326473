import React from 'react';

import Curves from '../components/CurveList'
import CurveForm from '../components/CurveForm';
import CurveForm2 from '../components/CurveForm2';

import axios from '../store/axioss';

import {connect} from 'react-redux';

import 'antd/dist/antd.css';

import { Layout, Menu, Breadcrumb, Dropdown, Button, Space, Select,message } from 'antd';

import { UserOutlined, StockOutlined} from '@ant-design/icons';


const { Option } = Select;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

class CurveListDetail extends React.Component {

    state = {
        curves: [],
        instruments:[],
        curveIDs: [],
        changedID: false,
        addCurveId: null,
    }
    handleFieldChange = (field, value) => {
        let curves = this.state.curves
        let id = this.state.selectedID
        this.setState({changedID:false})
        // use here event or value of selectedKey depending on your component's event
        let selectedCurve = curves.find(c => c.id == id)
        selectedCurve[field] = value;
        curves[curves.findIndex(c=>c.id==id)] = selectedCurve
        this.setState({ curves });
        this.forceUpdate();
    }

    componentDidMount() {
        this.load_data()
    }
    componentDidUpdate(prevProps, prevState) {
    if (this.state.curves === null) {
      this.load_data();
    }
  }

    load_data() {
        console.log("Loading data")
        console.log(this.state)
        if(this.props.token){
            axios.defaults.headers.common = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
            }
            console.log('token view');
            console.log(this.props.token);
            axios.get('/api/instr/')
                        .then(
                            res => {
                                this.setState({
                                    instruments: res.data
                                });
                            }
                        )
                        .then( res =>{
                            axios.get('/api/curve/')
                            .then(
                                res => {
                                    this.setState({
                                        curves: res.data,
                                        selectedID: String(res.data[0].id)
                                    })
                                }
                            )
                            .catch( error => {console.log(error)})
                        })
                        .catch( error => {console.log(error)})

        }
    }

    handleMenuClick = (e) => {
        console.log('Menu')
        console.log(e)
        if(e.key != this.state.selectedID)
        this.setState({
                    selectedID:e.key,
                    changedID:true
                });
    }

    handleChange = (value) => {
      console.log(value);
      this.setState({
        addCurveId:value,
        AddCurveSelect:value,

      });// { value: "lucy", key: "lucy", label: "Lucy (101)" }
    }

    addCurve = (e) => {
        console.log(this.state.addCurveId);
        if (this.state.addCurveId){
            const curve = {instrument:this.state.addCurveId, points:[]}
            return axios.post(`/api/curve/`,
              curve
            )
              .then(res => {
                console.log("Update result");
                console.log(res);
                this.setState({
                               changedID:true,
                               curves:this.state.curves.concat(res.data),
                                selectedID: res.data.id.toString(),
                                AddCurveSelect:null
                            })
                //this.forceUpdate();
              })
              .catch(error => console.error(error))
        }
    }

    handleDeleteCurve = (e) => {
        console.log("Deleting curve")
        console.log(this.state)
        const curveID = this.state.selectedID;
        return axios.delete(`/api/curve/${curveID}/`)
        .then( res => {
            console.log("CurveDeleted")
            console.log(res)
            var index = this.state.curves.findIndex( c => c.id == curveID)
            console.log(index)
            console.log(this.state.curves)
            let newCurves = this.state.curves
            newCurves.splice(index,1)
            console.log('newCurves')
            console.log(newCurves)
            this.setState({
                changedID:true,
                curves: newCurves,
                selectedID:null,
            })

            //this.forceUpdate();
            //window.location.reload();
        })
    }

    render() {
        console.log('rendering CurveListDetail')
        return (
            <div>
                <Layout style={{lineHeight:'20px',marginBottom: 0,marginTop: 0, height:'90vh',backgroundColor:'#041527'}}>
                    <Sider style={{width:200}}  theme="dark">
                        <div>
                            <Menu
                              mode="inline"
                              theme="dark"
                              selectedKeys={[this.state.selectedID]}
                              defaultOpenKeys={['sub1']}
                              style={{  borderRight: 0, padding:0 }}
                            >

                                <SubMenu key="sub1" icon={<StockOutlined />} title="Curves">
                                    {
                                        this.state && this.state.curves && this.state.instruments ?
                                        this.state.curves.map((c,i) => <Menu.Item key={c.id} onClick = {this.handleMenuClick}>{this.state.instruments.find(ci => ci.id==c.instrument).name}</Menu.Item>)
                                        :
                                        <a/>
                                    }
                                </SubMenu>
                            </Menu>
                            <Space wrap>
                                <Select style={{ width: 120, marginTop:'auto',backgroundColor:'#041527' }} allowClear onChange={this.handleChange} value = {this.state.AddCurveSelect}>
                                        {
                                             this.state && this.state.curves && this.state.instruments ?
                                             this.state.instruments.map(c =>
                                                this.state.curves.filter(d => d.instrument === c.id).length!==0 ?
                                                <Option value={c.id} disabled>{c.name}</Option>
                                                :
                                                <Option value={c.id}>{c.name}</Option>
                                             )
                                             :
                                             <a/>
                                        }
                                </Select>
                                 <Button onClick={this.addCurve} disabled = {this.state.AddCurveSelect?false:true}>Add</Button>
                            </Space>
                        </div>
                    </Sider >
                            <CurveForm2 data={this.state.instruments}
                                requestType='put'
                                curveID={this.state.selectedID}
                                btnText='Update'
                                curve={this.state.curves.find( c => c.id == this.state.selectedID)}
                                onChange={this.handleFieldChange}
                                changedID={this.state.changedID}
                                deleteCurve = {this.handleDeleteCurve}
                            />

                </Layout>
            </div>
        )
    }
}
const mapStateToProps = state => {
  return {
    token: state.token
  }
}


export default connect(mapStateToProps)(CurveListDetail);