import React from 'react';

import Curves from '../components/CurveList'
import axios from 'axios';

import { Card, Button } from 'antd';
import CurveForm from '../components/CurveForm';


class CurveDetail extends React.Component {

    state = {
        curve: {},
        instruments: []
    }
    handleFieldChange = (field, value) => {
        let curve = { ...this.state.curve };
        // use here event or value of selectedKey depending on your component's event
        curve[field] = value;
        this.setState({ curve });
    }


    componentDidMount() {
        const curveID = this.props.match.params.curveID;


        axios.get(`http://127.0.0.1:8000/api/curve/${curveID}/`)
            .then(
                res => {
                    this.setState({
                        curve: res.data
                    });
                    console.log(this.state.curve);
                    axios.get('http://127.0.0.1:8000/api/instr/')
                    .then(
                        res => {
                            this.setState({
                                instruments: res.data
                            });
                            console.log('instruments');
                            console.log(res.data);
                        }
                    )
                }
            );


    }
    handleDelete = (event) => {
        const curveID = this.props.match.params.curveID;
        axios.delete(`http://127.0.0.1:8000/api/${curveID}/`);
        this.props.history.push('/');
        this.forceUpdate();
    }
    render() {
        console.log('rendering CurveDetail')
        return (
            <div>

                <CurveForm data={this.state.instruments}
                    requestType='put'
                    curveID={this.props.match.params.curveID}
                    btnText='Update'
                    curve={this.state.curve}
                    onChange={this.handleFieldChange}
                />

            </div>
        )
    }
}

export default CurveDetail;