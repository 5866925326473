import React from 'react';

import { Layout, Menu, Breadcrumb, Space, Select } from 'antd';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import {UserOutlined, MailOutlined, LockOutlined,LoadingOutlined,HomeOutlined,StockOutlined} from '@ant-design/icons'
import axios from '../store/axioss';
import DropDownEditor from '../components/DropDownEditor'
import FixedReactDataGrid from '../components/DataGridForm';

import { Row, Col } from 'antd';
import {Area} from '@ant-design/charts'


const { Header, Content, Footer, Sider } = Layout;
const {SubMenu, MenuItemGroup} = Menu

const { Option } = Select;

const curveColumns = [
  { key: "period", name: "Period", editable: false },
  { key: "rate", name: "Rate", editable: false },
  { key: "discount", name: "Discount Factor", editable: false },

];

const quoteColumns = [
  { key: "company", name: "Company", editable: false },
  { key: "quote", name: "Quotes", editable: false },
  { key: "yields", name: "Yield", editable: false },
  { key: "size", name: "Size", editable: false },
  { key: "time", name: "Time", editable: false },
];

class Home extends React.Component {

    state = {
        instruments:[],
        curve:[],
        selectedQuote:'',
        showQuote:false
    }

    load_data(){
        if(this.props.token){
            axios.defaults.headers.common = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
            }
            axios.get('/api/instr/')
                        .then(
                            res => {
                                console.log('home instr');
                                console.log(res.data);

                                this.setState({
                                    instruments: res.data
                                });
                            }
                        )
                        .then(
                            res=> this.loadQuotes(res)
                        )
                        .catch( error => {console.log(error)})
        }
    }
    componentDidMount() {
        this.load_data()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.instruments === null) {
          this.load_data();
        }
    }

    handleMenuClick = (e) => {
        axios.defaults.headers.common = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
        }
//         console.log(e)
        axios.get('/api/crv/'+e.key)
                        .then(
                            res => {
                                this.setState({
                                    curve: res.data,
                                    showQuote: false
                                });

                            }
                        )

                        .catch( error => {console.log(error)})
    }
    loadQuotes = (e) => {
        axios.defaults.headers.common = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
        }
//         console.log(e)
        axios.get('/api/allq/')
                        .then(
                            res => {
                                this.setState({
                                    quotes: res.data
                                });
                                console.log('quotes loaded')
                                console.log(this.state.quotes)
                            }
                        )

                        .catch( error => {console.log(error)})
    }
    handleSelectQuote= value => {
        console.log('setting quote')
        console.log(value)
        this.setState({
                selectedQuote:value,
                showQuote:true
        });
    };

    renderCurves = (state) => {
        return(
            <Row>
                <Col span={10}>
                    <div style={{padding:6,width:400}}>
                    <FixedReactDataGrid
                        rows={this.state.curve}
                        columns={curveColumns}
                        editable={false}
                        changedID={true}
                        />
                    </div>
                </Col>
                <Col span = {14}>
                    <div style={{padding:24}}>
                        <Area data={this.state.curve.map( x => ({name: x.period,value: Number(x.rate)}))}
                                        xField= "name"
                                        yField= "value"
                                        areaStyle= {{
                                        fill: 'l(90) 0.0:#54AABC 0.5:#1D415A 1.0:#050A2F',fillOpacity:0.9}}
                                        grid= {{
                                          line: {
                                            style: {
                                              stroke: 'black',
                                              lineWidth: 2,
                                              lineDash: [4, 5],
                                              strokeOpacity: 0.7,
                                              cursor: 'pointer',
                                              color: '#ffffff'
                                            }
                                          }
                                        }}
                                        line={{color:'#ffffff'}}
                                        smooth={true}
                                        yAxis={{ autoRotate:false,
                                                reverse:false

                                                }}/>
                    </div>
                </Col>

            </Row>
        )
    }

    renderQuotes = (state) =>{
        console.log('render all quotes')
        console.log(this.state.selectedQuote)
        console.log(this.state.quotes.filter(q => q.shortname === this.state.selectedQuote))
        return(
            <Col span={10}>
                    <div style={{padding:6,width:600}}>
                    <FixedReactDataGrid
                        rows={this.state.quotes.filter(q => q.shortname === this.state.selectedQuote)}
                        columns={quoteColumns}
                        editable={false}
                        changedID={true}
                        />
                    </div>
            </Col>

        )
    }




    render(){
        return (
            <Layout theme="dark" style={{lineHeight:'20px',marginBottom: 0,marginTop: 0, height:'90vh', background: '#041527'}}>
                <Sider style={{marginBottom: 0,padding:0 , span:6}}  theme="dark">
                    <Menu
                              mode="inline"
                              theme="dark"
                              defaultSelectedKeys={['h'+this.state.instruments[0]]}
                              defaultOpenKeys={['hsub1']}
                              style={{  borderRight: 0, padding:0 }}
                            >
                        <SubMenu key="hsub1" icon={<StockOutlined />} title="Curves">
                                    {
                                        this.state && this.state.instruments ?
                                        this.state.instruments.map((c,i) => <Menu.Item key={c.name} onClick = {this.handleMenuClick}>{c.name}</Menu.Item>)
                                        :
                                        <a/>
                                    }
                        </SubMenu>
                        <SubMenu key='hbnd' title = 'Bonds' onClick={this.loadQuotes}>
                            {
                            this.state && this.state.quotes ?
                            <Select showSearch
                                style={{ width: 200 }}
                                placeholder="Bond"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onSelect = {this.handleSelectQuote} onChange= {this.handleSelectQuote}
                                >
                                {[...new Set(this.state.quotes.map(item => item.shortname))].map(s => <Option key={s}>{s}</Option>)}
                            </Select>
                            :
                            <a/>
                            }
                        </SubMenu>
                    </Menu>
                </Sider>

                {
                    this.state.showQuote?
                    this.renderQuotes(this.state)
                    :
                    this.renderCurves(this.state)
                }
            </Layout>
        )
    }
}
const mapStateToProps = state => {
  return {
    token: state.token
  }
}


export default connect(mapStateToProps)(Home);
