import React from 'react';

import axios from '../store/axioss';

import FixedReactDataGrid from './DataGridForm';
import { Form, Input, Button, Select, Descriptions, Layout, message } from 'antd';
import {Area} from '@ant-design/charts'
import { Row, Col } from 'antd';
import {connect} from 'react-redux'

import { range } from "lodash";

import DropDownEditor from './DropDownEditor'
//import { Editors } from "react-data-grid-addons";

//const { DropDownEditor } = Editors;
const curve_points = [
        {curve:'MOSPRIME_3M', points:[
            { id: '3M', value: '3 MO' },
            { id: 'FRA3X6', value: 'FRA 3X6' },
            { id: 'FRA6X9', value: 'FRA 6X9' },
            { id: '1Y', value: '1 YR' },
            { id: '2Y', value: '2 YR' },
            { id: '3Y', value: '3 YR' },
            { id: '4Y', value: '4 YR' },
            { id: '5Y', value: '5 YR' },
            { id: '6Y', value: '6 YR' },
            { id: '7Y', value: '7 YR' },
            { id: '8Y', value: '8 YR' },
            { id: '9Y', value: '9 YR' },
            { id: '10Y', value: '10 YR' },
            ]
        },
        {curve:'RUONIA',points:[
            { id: '1D', value: '1 D' },
            { id: '1W', value: '1 WK' },
            { id: '2W', value: '2 WK' },
            { id: '1M', value: '1 MO' },
            { id: '2M', value: '2 MO' },
            { id: '3M', value: '3 MO' },
            { id: '6M', value: '6 MO' },
            { id: '6M', value: '9 MO' },
            { id: '1Y', value: '1 YR' },
            { id: '2Y', value: '2 YR' },
            ]
        },
        {curve:'KEY_RATE', points:[
            { id: '1W', value: '1 WK' },
            { id: '3M', value: '3 MO' },
            { id: '6M', value: '6 MO' },
            { id: '9M', value: '9 MO' },
            { id: '1Y', value: '1 YR' },
            { id: '2Y', value: '2 YR' },
            { id: '3Y', value: '3 YR' },
            { id: '4Y', value: '4 YR' },
            { id: '5Y', value: '5 YR' },
            { id: '6Y', value: '6 YR' },
            { id: '7Y', value: '7 YR' },
            { id: '8Y', value: '8 YR' },
            { id: '9Y', value: '9 YR' },
            { id: '10Y', value: '10 YR' },
            ]
        },]
//const PointTypeEditor = <DropDownEditor options={pointTypes} />;

const curveColumns = [
                  { key: "name", name: "Point", editable: false, width:80 },
                  { key: "value", name: "Value", editable: true },
                  { key: "rate", name: "Rate", editable: false, on:"name",from:"rate" },
                  { key: "discount", name: "Discount", editable: false, on:"name",from:"discount" },
                ];


const defaultParsePaste = str => {
  console.log(str);
  return str.split(/\r\n|\n|\r/).map(row => row.split("\t"));
};
const changedValues = {};

class CurveForm2 extends React.Component {
    state = { curve: {}};
    constructor(props) {
        super(props);
        try {
            let curve = props.curve
            this.curve = curve

        }
        catch { }

    }

    create_columns() {

    }

    get_bootstrap = async (rows) => {
        if(this.state.curve){
            axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
            }
            console.log('bootstraping')
            console.log(rows)
            const res = await axios.get('/api/crv', {params: {'curve_name':this.state.curve.curve_name,'pnts':rows}})

            console.log("Got bootstrap");
            console.log(res);
            return res.data;
        } else {
            return undefined
        }
    }


    fieldValueChanged = (e) => {
        console.log(e);
        if (e.target)
            this.props.onChange(e.target.name, e.value);
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.curves !== null) {
            this.create_columns()
        }
    }
    componentWillReceiveProps = (props) => {
        console.log('received Props Curve Form');
        console.log(props);
        this.state.curve=props.curve;
//         this.get_bootstrap(props.curve);
        this.setState({curve:props.curve});
    }


  handleFormSubmit = (event, requestType, curveID) => {
    if(this.props.token !== null)
    {
        axios.defaults.headers = {
                "Content-Type": "application/json",
                Authorization: 'Token '+this.props.token
        }
        console.log(this.state.curve);
        let curve = this.state.curve;
        curve.upd_time = (new Date()).toJSON();


        switch (this.props.requestType) {
          case 'post':

            return axios.post('/api/', curve)
              .then(res => console.log(res))
              .catch(error => console.error(error))



          case 'put':
            let crv = curve;
            crv.points=curve.points.filter(x => x.value !=="")
            console.log('put');
            console.log(crv);
            return axios.put(`/api/curve/${curveID}/`,
              crv
            )
              .then(res => {
                console.log("Update result");
                console.log(res);
                this.state.curve = res.data;
//                 this.get_bootstrap(res.data.points);
                //this.setState({curve:res.data});
                this.props.onChange('points', res.data.points);
                message.success('Curve updated')
//                 this.forceUpdate();
              })
              .catch(error => {message.error(error.response.data.message)})
        }
    }

  }


  fieldValueChanged = (f,v) => {
    if (f){
        const curv = this.state.curve
        curv[f]=v
        this.setState({curve:curv})
        this.props.onChange(f, v);

      }
  }


/*     renderPoints = () => {

        if (this.state.curve && this.state.curve.points && this.state.bootstrap){
            const pnts = this.state.curve.points;
            return (
                    <FixedReactDataGrid
                        rows={curve_points
                            .find(x => x.curve === this.props.data.find(ci => ci.id === this.state.curve.instrument).name).points
                            .map(pt => ({name: pt.value, value: this.state.curve.points.find(p => p.name === pt.value)?
                            pnts.find(p => p.name === pt.value).value : ''}))
//                             .map(pt => Object.assign({},pt,this.state.bootstrap.find(b => b.period===pt.name)))
                            }
                        columns={curveColumns}
                        onChange={this.fieldValueChanged}
                        changedID={this.props.changedID}
                        editable={true}
                        canAddRows={false}
                        getExtraData={this.get_bootstrap}
                        />
            )
        }
        else return (<a />)

    } */

    render() {
        console.log('Render Curve Form')
        console.log(this.state);
        console.log(this.state.curve);

        const isUpdate = this.props.requestType === 'put';
        if(this.state.curve && this.state.curve.points){
        return (
                <Layout style={{padding: '20px 20px',backgroundColor:'#041527'}}>
                <Row>
                <Col span={12}>
                    <Form
                          name="basic"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 16 }}
                          onFinish = {(e) => this.handleFormSubmit(e,this.props.requestType,this.props.curveID)}>
                        <Form.Item name='time' label="Last update:" enabled='false' style={{height:20}}>{(new Date(this.state.curve.upd_time)).toLocaleString()}</Form.Item>



                        <Form.Item style={{height:20}}>
                            <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
                                Update Curve
                              </Button>

                            <Button type="danger" onClick={this.props.deleteCurve}>
                                Delete Curve
                            </Button>
                        </Form.Item>
                    </Form>
                    <FixedReactDataGrid
                        rows={curve_points
                            .find(x => x.curve === this.state.curve.curve_name).points
                            .map(pt => ({name: pt.value, value: this.state.curve.points.find(p => p.name === pt.value)?.value}))
                            }
                        columns={curveColumns}
                        onChange={this.fieldValueChanged}
                        changedID={this.props.changedID}
                        getExtraData={this.get_bootstrap}
                        editable={true}
                        canAddRows={false}/>
                </Col>
                <Col span = {12}>
                    <Area data={this.state.curve.points.filter(x => x.value !== '').map( x => ({name: x.name,value: Number(x.rate)}))}
                                    xField= "name"
                                    yField= "value"
                                    areaStyle= {{
                                        fill: 'l(90) 0.0:#54AABC 0.5:#1D415A 1.0:#050A2F',fillOpacity:0.9}}
                                        grid= {{
                                          line: {
                                            style: {
                                              stroke: 'black',
                                              lineWidth: 2,
                                              lineDash: [4, 5],
                                              strokeOpacity: 0.7,
                                              cursor: 'pointer',
                                              color: '#ffffff'
                                            }
                                          }
                                        }}
                                        line={{color:'#ffffff'}}
                                        smooth={true}
                                        yAxis={{ autoRotate:false,
                                                reverse:false

                                                }}/>

                </Col>
                </Row>
                </Layout>
        );
        }
        else
        return (<p/>)
    }
}

const mapStateToProps = state => {
  console.log('mapStateToProps')
  console.log(state)

  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(CurveForm2);