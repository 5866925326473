import React from 'react';

import { Layout, Menu, Breadcrumb, Space } from 'antd';

import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import {UserOutlined, MailOutlined, LockOutlined,LoadingOutlined,HomeOutlined,StockOutlined,BookOutlined} from '@ant-design/icons'
import Home from '../landing/index.jsx'
import Login from '../containers/Login';


import { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header1 from '../landing/Nav0' ;
import Footer1 from '../landing/Footer0' ;
// import Home from '../landing';

import {
  Nav00DataSource,
  Footer00DataSource,
} from '../landing/data.source.js';

const {SubMenu, MenuItemGroup} = Menu


const { Header, Content, Footer, Sider } = Layout;
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class CustomLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isMobile,
          collapsed: true,
//           selectedTab: '/'
        };
      }


    componentDidMount() {
//         this.setState({selectedTab:'/'})
        enquireScreen((b) => {
          this.setState({ isMobile: !!b });
        });
    }
//     componentDidUpdate(prevProps, prevState) {
//         if (this.state.selectedTab === null) {
//           this.setState({selectedTab:'/'})
//         }
//     }

    onCollapse = (collapsed: boolean) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    user_logout = (e) => {
         this.props.logout();
         window.location.reload();
    }

    handleMenuClick = (e) =>{
        console.log("menu Click")
        this.setState({ selectedTab: e.key });

    }


    render() {
        console.log('CustomLayout props')
        console.log(this.props)
        return (
                this.props.isAuthenticated ?
                <Layout className="layout" style={{height:'100%'}} >
                    <Header>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            style={{ lineHeight: '64px' }}
                            defaultSelectedKeys={['/']}
                            selectedKeys={[this.state.selectedTab]}
                        >
                            <Menu.Item key="/" style={{marginLeft:0}} icon={<HomeOutlined />} onClick={this.handleMenuClick}>
                                <NavLink to='/' className="nav-text"></NavLink>
                            </Menu.Item>
                            <Menu.Item key="/mycurves" style={{marginLeft:0}} icon={<StockOutlined />} onClick={this.handleMenuClick}>
                                <NavLink to='/mycurves' className="nav-text">My Curves</NavLink>
                            </Menu.Item>
                            <Menu.Item key="/myquotes" style={{marginLeft:0}} icon={<BookOutlined />} onClick={this.handleMenuClick}>
                                <NavLink to='/myquotes' className="nav-text">My Quotes</NavLink>
                            </Menu.Item>
                            <SubMenu key='lsub1' icon ={<UserOutlined />} title={this.state && this.state.user ? this.state.user:localStorage.getItem('user')} style={{marginLeft:'auto'}} theme='light'>
                                <Menu.Item key="3" onClick={this.user_logout} style={{marginLeft:'auto'}}>
                                    Выйти
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Header>
                    <Layout>
                        <Content>
                            <div style={{ backgroundColor:'#041527', margin: 0 }}>
                                {this.props.children}
                            </div>
                        </Content>
                        <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} width={'400px'} padding={0} theme="dark">
                            <iframe style={{width: '100%', border: '0', padding:0}} src="https://dev.local:1234/"></iframe>
                        </Sider>
                    </Layout>
                    <Footer style={{ textAlign: 'center', backgroundColor:'#041527' }}>
                    </Footer>

                </Layout>
                :
                <Router>
                    <div>
                      <Header1 dataSource={Nav00DataSource} isMobile={this.isMobile} />
                      <Route exact path="/" component={Home} />
                      <Route exact path="/login" component={Login} />
                      <Footer1 dataSource={Footer00DataSource} isMobile={this.isMobile} />
                    </div>
                </Router>

        )
    }
}



const mapDispatchToProps = dispath => {
    return {
        logout: () => dispath(actions.logout())
    }
}
const mapStateToProps = state => {
  console.log('mapStateToProps Layout')
  console.log(state)

  return {
    user: state.user
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLayout))


