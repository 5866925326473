import * as actionTypes from './actionTypes';

import axios from '../axioss';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token, user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user: user
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const user_logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('user');

    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const logout = () => {
    return dispath => {
        dispath(user_logout())
    }
}

export const checkAuthTimeout = expirationTime => {
    return dispath => {
        setTimeout(() => {
            dispath(user_logout());
        }, expirationTime * 1000);
        console.log('settimeout ok')
    }
}

export const authLogin = (username, password) => {
    return dispath => {
        dispath(authStart());
        console.log(username, password);
        let res = axios.post('/dj-rest-auth/login/', {
                username: username,
                password: password
            })
                .then(res => {
                    console.log(res);
                    const token = res.data.key;
                    const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
                    localStorage.setItem('token', token);
                    localStorage.setItem('expirationDate', expirationDate);

                })
                .then( res => {
                    axios.defaults.headers = {
                            "Content-Type": "application/json",
                            Authorization: 'Token '+localStorage.getItem('token')
                    }
                    let usr= axios.get(`/dj-rest-auth/user/`)
                                    .then(usr => {
                                        console.log("got usr")
                                        console.log(usr)
                                        localStorage.setItem('user', usr.data.username);
                                        dispath(authSuccess(localStorage.getItem('token'), usr.data.username));
                                        dispath(checkAuthTimeout(3600));
                                    })
                })

                .catch(error => {
                    dispath(authFail(error));
                })

    }
}

export const authSignup = (username, password1, password2) => {
    return dispath => {
        dispath(authStart());
        axios.post('/dj-rest-auth/registration/', {
            username: username,
            password1: password1,
            password2: password2
        })
            .then(res => {
                const token = res.data.key;
                const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
                console.log(expirationDate)
                localStorage.setItem('token', token);
                localStorage.setItem('expirationDate', expirationDate);
            })
            .then( res => {
                    axios.defaults.headers = {
                            "Content-Type": "application/json",
                            Authorization: 'Token '+localStorage.getItem('token')
                    }
                    axios.get(`/dj-rest-auth/user/`)
                        .then(usr => {
                            console.log("got usr")
                            console.log(usr)
                            localStorage.setItem('user', usr.data.username);
                            axios.get('/api/sec/')
                                .then(sec => {
                                    console.log('sec on login')
                                    console.log(sec)
                                    localStorage.setItem('securities',JSON.stringify(sec.data))
                                    dispath(authSuccess(localStorage.getItem('token'), localStorage.getItem('user')));
                                    dispath(checkAuthTimeout(3600));
                                })
                        })
                })

                .catch(error => {
                    dispath(authFail(error));
                })
            .catch(error => {
                dispath(authFail(error));
            })

    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');
        if (token === undefined) {
            dispatch(user_logout());
        }
        else {
            console.log('get expirationDate')
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            console.log(expirationDate)
            try{
                if (expirationDate <= new Date()) {
                    dispatch(user_logout());
                } else {
                    dispatch(authSuccess(token, user));
                    console.log('token ok');
                    dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
                    console.log('check timeout ok');
                }
            }
            catch(error){
                console.log(error)
            }


        }
    }
}
