import React from 'react';
import Login from '../containers/Login'
import Logo from '../pics/neural_byBecris_withTitle.png'
import Bond from '../pics/Gradient_Bond_byEucalyp.png'
import Curve from '../pics/curves_byDreamStale.png'
import Search from '../pics/search-engine_byDreamstale.png'
import Serezha from '../pics/maxresdefault.jpeg'
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page l3podb54hj-editor_css' },
  logo: {
    className: 'header0-logo',
    children:
      Logo,
  },
  Menu: {
    className: 'header0-menu',

    children: [
      {
        name: 'item0',
        mode: 'inline',
        className: 'header0-item',
        popup: <Login/>,
        children: {
          children: [
            {
              children: (
                <span>
                  <p>Войти</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },

      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 l3pnvija2ro-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children:
      <img src={Logo} width='100%'/>,
  },
  content: {
    className: 'banner0-content l3po0gp0de-editor_css',
    children: (
       <span>
        <span>
          <p>
            Система Обмена Финансовой Информацией<br />
          </p>
        </span>
      </span>
    ),
  },
  button: { className: 'banner0-button', children:
      <span>
        <p>
          Узнать больше<br />
        </p>
      </span> },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              Система позволяет<br />
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img src={Bond} width='100%'/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>
                                Котировать облигации и видеть котировки других
                                участников рынка.
                              </p>
                              <p>
                                <br />
                              </p>
                              <p>
                                СОФИ формирует эталонные цены на основе
                                котировок участников рынка, по которым можно
                                переоценивать портфель.<br />
                              </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>

                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img src={Curve} width='100%'/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                 <p>
                          Котировать свопы с базовыми ставками: Mosprime,
                          RUONIA, Key Rate.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          СОФИ выстраивает проинтерполированную spot-кривую,
                          которую можно использовать для переоценки портфеля.<br />
                        </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>

                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                 <img src={Search} width='100%'/>,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                   <p>
                                Ознакомиться с прозрачными методиками
                                формирования эталонных цен и интерполяции
                                кривых, разработанные совместно с СРО "НФА"<br />
                              </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>

                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              Наша команда<br />
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image l3poina8px-editor_css',
              children:
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9KKBMtFsv5OQwouR4R6AhilRNjhpoL5HBzNMfnc89ityqy0mcrZTcG92K_pqZnravYFE&usqp=CAU',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>Ivan</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    Автор идеи <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    Иван работает на финансовом рынке более шести лет. Выполнял
                    различные роли, в том числе рыночного рисковика,
                    структуратора, а так же трейдера, сначала по процентным
                    деривативам, а затем - по облигациям.
                  </p>
                  <p>
                    Иван является выпускником Даремского университета по
                    направлению "Экспериментальная экономика", обладает наградой
                    университета за лучший академический результат на курсе.{' '}
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>Николай</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <span>
                    <p>
                      Quant<br />
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Опыт Николая на финансовых рынках насчитывает более семи
                        лет, в том числе в роли рисковика и риск-кванта по
                        структурным продуктам. За плечами Николая бакалавриат и
                        магистратура МФТИ по прикладным математике и физике.
                        Николай является автором научных статей по финансовой
                        математике, опубликованных в научных реферируемых
                        журналах, а также преподавал финансовую математику в
                        ряде вузов, включая МФТИ, ВШЭ и РЭШ.<br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image l3poqasx3ks-editor_css',
              children: <img src={Serezha} width='100%'/>,
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>
                    Таинственный мистер Х<br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    Очень опытный и очень важный человек в нашей команде,
                    который настолько суров, что мы вам про него не расскажем :)<br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <br />
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Nashi tehnologii</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              Думаю, обойдемся без технологий пока<br />
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Glazik!</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <blockquote>Glazik</blockquote>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Bukva A</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>Bukva</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Kalendar</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>Kalendarik</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Tychka</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>Palzem</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Nokia</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>Raskladushka</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Parker</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>Ruchka</p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2018 <a href="https://motion.ant.design">Ant Motion</a> All Rights
        Reserved
      </span>
    ),
  },
};
