import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import BaseRouter from './routes';
import 'antd/dist/antd.css';
import * as actions from './store/actions/auth';
import { enquireScreen } from 'enquire-js';

import CustomLayout from './containers/Layout';
import './App.css';
import WebSocketInstance from './websocket'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    console.log('onTryAutoSignup')
    this.props.onTryAutoSignup();
    console.log('onTryAutoSignup ok')
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
//     WebSocketInstance.connect()
  }

  render() {
    return (
      <div >
        <Router>
          <CustomLayout {...this.props}>
            <BaseRouter />
          </CustomLayout> 
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('mapStateToProps')
  console.log(state)

  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispathToProps = dispatch => {
  console.log('mapStateToProps')
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
  
}

export default connect(mapStateToProps, mapDispathToProps)(App);
