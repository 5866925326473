import axios from "axios";


//axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
//axios.defaults.xsrfCookieName = "csrftoken";
//axios.defaults.withCredentials = true;
//axios.defaults.headers.post['X-CSRF-Token'] = response.data._csrf;
axios.defaults.baseURL = 'https://finexsys.ru/'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/'

export default axios;